@tailwind base;
@tailwind components;
@tailwind utilities;



/* Variables */

:root {
  /* dark shades of primary color*/
  --clr-primary-1: hsl(205, 86%, 17%);
  --clr-primary-2: hsl(205, 77%, 27%);
  --clr-primary-3: hsl(205, 72%, 37%);
  --clr-primary-4: hsl(205, 63%, 48%);
  /* primary/main color */
  --clr-primary-5: hsl(205, 78%, 60%);
  /* lighter shades of primary color */
  --clr-primary-6: hsl(205, 89%, 70%);
  --clr-primary-7: hsl(205, 90%, 76%);
  --clr-primary-8: hsl(205, 86%, 81%);
  --clr-primary-9: hsl(205, 90%, 88%);
  --clr-primary-10: hsl(205, 100%, 96%);
  /* darkest grey - used for headings */
  --clr-grey-1: hsl(209, 61%, 16%);
  --clr-grey-2: hsl(211, 39%, 23%);
  --clr-grey-3: hsl(209, 34%, 30%);
  --clr-grey-4: hsl(209, 28%, 39%);
  /* grey used for paragraphs */
  --clr-grey-5: hsl(210, 22%, 49%);
  --clr-grey-6: hsl(209, 23%, 60%);
  --clr-grey-7: hsl(211, 27%, 70%);
  --clr-grey-8: hsl(210, 31%, 80%);
  --clr-grey-9: hsl(212, 33%, 89%);
  --clr-grey-10: hsl(210, 36%, 96%);
  --clr-white: #fff;
  --clr-red-dark: hsl(360, 67%, 44%);
  --clr-red-light: hsl(360, 71%, 66%);
  --clr-green-dark: hsl(125, 67%, 44%);
  --clr-green-light: hsl(125, 71%, 66%);
  --clr-black: #222;
  --transition: all 0.3s linear;
  --spacing: 0.1rem;
  --radius: 0.25rem;
  --light-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  --dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  --max-width: 1170px;
  --fixed-width: 620px;


  /* @@@@@@@@ Testimonial @@@@@@@ */

  --card-clr: #161922;
	--body-clr: #191d28;
	--primary-clr: #f0bf6a;
	--heading-clr: #dadada;
	--text-clr: #767a86;
}

/* body {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  line-height: 1.6em;
  color: #5d6475;
} */


/* end of variables */
*{
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  max-width: 100%;
}

/* @@@@@@@@@@@Borrowed@@@@@@@ */
body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  line-height: 1.5;
  font-size: 0.875rem;
}


h1,
h2,
h3,
h4 {
  letter-spacing: var(--spacing);
  text-transform: capitalize;
  line-height: 1.25;
  margin-bottom: 0.75rem;
}
h1 {
  font-size: 3rem;
}
h2 {
  font-size: 2rem;
}
h3 {
  font-size: 1.25rem;
}
h4 {
  font-size: 0.875rem;
}
p {
  margin-bottom: 1.25rem;
  color: var(--clr-grey-5);
}
@media screen and (min-width: 800px) {
  h1 {
    font-size: 4rem;
  }
  h2 {
    font-size: 2.5rem;
  }
  h3 {
    font-size: 1.75rem;
  }
  h4 {
    font-size: 1rem;
  }
  body {
    font-size: 1rem;
  }
  h1,
  h2,
  h3,
  h4 {
    line-height: 1;
  }
}
/*  global classes */


/* @@@@@@@@@@Borrowe@@@@@@@@@ */
html {
  scroll-behavior: smooth;
}


/* @@@@@@@@@@HEADER@@@@@@@@ */

.Header-handles {
  height: 50px;
  width: 100%;
  background: black;
  
}
.Header-handles .subheader-handles {
  width: 90%;
  height: 100%;
  background: black;
  /* color: #FADFA1; */
  color: #c69731;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;

}
.first-handles {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 70%;
}
.second-handles {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 15%;
}
.handle-text {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.handle-Fa {
  margin-right: 10px;
}

/* @@@@@@@Mobile Section@@@@@@@ */

@media(max-width: 1050px) {
  .second-handles {
    display: none;
  }
  .first-handles {
    width: 100%;
  }
  
}

@media(max-width: 770px) {
  .Header-handles {
    display: none;
  }
}


/* @@@@@@@@@@NAVBAR@@@@@@@@ */

.sliding-Maindiv {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sliding-img-section {
  /* display: none; */
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.sliding-img {
  /* position: absolute; */
  display: flex;
  width: 100%;
  height: 100%;
  
}

.the-img {
  height: 100%;
  width: 100%;
  transition: transform 0.4s ease-in-out;
  
}
.sliding-img-section .nav-elements {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}

.main-nav {
  
  display: flex;
  justify-content: center;
  height: 150px;
  width: 100%;
  align-items: center;
  /* background-color: aquamarine; */
}
.nav-list {
  display: inline-flex;
  color: white;
  font-size: 20px;
  font-weight: bolder;
  cursor: pointer;
}
.nav-centerDiv {
  /* width: 100%; */
  /* height: 50px; */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
}
.nav-list li {
  list-style: none;
  padding: 15px 25px;
  
}

.book {
  border: 1px solid white;
  padding: 15px 25px;
  background: none;
  color: white;
  /* font-size: 20px; */
  font-weight: bolder;
  cursor: pointer;
}
.book:hover {
  background: #c69731;
  border: 1px solid #c69731;
  /* padding: 15px 25px; */
  color: white;
  border: none;
  transition: 0.5s;
}
.book-arrow {
  padding-left: 5px;
}

.ket-logo {
  width: 150px;
  height: 120px;
}
.middle-content {
  max-width: 100%;
}
.angle {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: white;
  font-size: 30px;
}
.angle.angle-left {
  left: 10px;
  cursor: pointer;
}
.angle.angle-right {
  right: 10px;
  cursor: pointer;
}
.angle.angle-left:hover {
  color: #c69731;
}
.angle.angle-right:hover {
  color: #c69731;
}
.angle.welcome {
  left: 50%;
  top: 40%;
  transform: translateX(-50%);

}
.welcome {
  width: 70%;
}
.welcome h2 {
  text-align: center;
}
.welcome p {
  font-size: 15px;
  text-align: center;
}
.welcome-mymuka {
  
  color: whitesmoke;
}
.push-h2 {
  /* margin: 50px 0px; */
  font-family: "Playfair Display", serif;
  
}
.wetttsp {
  font-size: 14px;
  font-weight: bold;
  font-style: italic;
}

/* @@@@@@@@@@@@@ The Mobile @@@@@@@@@@@@@ */
.the-mobile {
  display: none;
  width: 100%;
  height: 100vh;
  background-image: linear-gradient(rgba(4,9,30,0.7), rgba(4,9,30,0.7)), url("./Background-images/blur.jpg");
  background-position: center;
  background-size: cover;
  text-align: center;
}
.welc-tex {
  padding: 0px 14px;
}
.p-h2 {
  /* color: whitesmoke; */
  color: #c69731;
  margin: 50px 0px;
  font-family: "Playfair Display", serif;
  font-size: 50px;
}
.welc-mymuka {
  color: whitesmoke;

}
/* @@@@@@@@@@@@@ The Mobile @@@@@@@@@@@@@ */

@media(max-width: 1050px) {
  
  .sliding-img-section {
    
    height: 120vh;
    
  }
  
}
@media(max-width: 860px) {
  .sliding-img-section {
    display: none;
    height: 140vh;
    
  }
  .the-mobile {
    display: block;
  }  
  .welcome h2 {
    font-size: 20px;
  }
}

@media(max-width: 660px) {
  .sliding-img-section {
    height: 120vh;
    
  }
  .p-h2 {
    margin: 20px 0px;
    font-size: 40px;
  }
  .welcome {
    width: 80%;
  }
  .welcome p {
    font-size: 13px;
    /* text-align: left; */
  }
  .welcome-mymuka {
    line-height: 1rem;
  }
  .welcome {
    margin-top: -130px;
  }
  .welcome-more {
    display: none;
  }
}

@media(max-width: 530px) {
  .sliding-img-section {
    height: 80vh;
    
  }
  .push-h2 {
    margin-top: 10px;
  }
}
@media(max-width: 410px) {
  .sliding-img-section {
    height: 75vh;
    
  }
  .p-h2 {
    margin: 20px 0px;
    font-size: 20px;
  }
  .welcome {
    margin-top: -160px;
  }
}
/* @@@@@@@ submenu @@@@@@ */

.sub-menu1 {
  display: none;
  color: black;
  font-size: 15px;
  cursor: pointer;
  background: white;
  transition: 1s;
}
/* .bottom-line {
  height: 2px;
  width: 100%;
  background-color: green;
} */
 .nav-list li::after {
  content: '';
  width: 0;
  height: 2px;
  display: block;
  background: #c69731;
  margin: auto;
  transition: 0.5s;
 }
 .leenk::after {
  content: '';
  width: 0;
  height: 2px;
  display: block;
  background: #c69731;
  margin: auto;
  transition: 0.5s;
 }
 .leenk:hover::after {
  width: 100%;
 }
 .nav-list li:hover::after {
  width: 100%;
 }
 .about-leenk {
  text-decoration: none;
  color: white;
 }

.nav-centerDiv:hover .sub-menu1 {
  display: block; 
  position: absolute;
  top: 90px;
  /* margin-top: 85px; */
  margin-left: -15px;
}
.leenk {
  text-decoration: none;
  color: white;
}
.leenk-sub {
  text-decoration: none;
  color: black;
}
.sub-sub-menu1:hover {
  background: rgb(48, 47, 47);
  color: white;
}
.nav-icon {
  visibility: hidden;
  color: white;
  font-size: 40px;
  cursor: pointer;
}
.nav-icon:hover {
  color: #c69731;
}
/* Phone-navbar */

/* ################## */
.sidebar-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
  visibility: hidden;
  z-index: -1;
  transition: var(--transition);
  transform: scale(0);
  background: rgba(0, 0, 0, 0.5);
}
.sidebar-wrapper.show {
  visibility: visible;
  z-index: 2;
  transform: scale(1);
}
.sidebar {
  width: 90vw;
  height: 95vh;
  max-width: var(--fixed-width);
  background: var(--clr-white);
  border-radius: var(--radius);
  box-shadow: var(--dark-shadow);
  position: relative;
  padding: 4rem 2rem;
}
.close-btn {
  font-size: 2rem;
  background: transparent;
  border-color: transparent;
  color: #c69731;
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
}
.close-btn:hover {
  color: #222;
}
.sidebar article {
  margin-bottom: 2rem;
}

.sidebar ul {
  display: block;
  margin-top: 15px;
  /* margin-left: -15px; */
  list-style: none;
}

.sidebar-links ul li {
  width: 190px;
  /* margin: 10px; */
  padding: 10px;
  background: #c69731;
  border-bottom: 1px dotted #222;
  cursor: pointer;
  transition: all 0.3s;
  color: white;
  

}
.phone-subnav {
  display: flex;
  justify-content: left;
  align-items: center;
  height: 40px;
  width: 190px;
  background: #c69731;
  padding-left: 10px;
  cursor: pointer;
  border-bottom: 1px dotted #222;

}
.phone-subnav:hover {
  color: white;
  background: #161922;
}
.side-pnlink {
  text-decoration: none;
  color: white;
  padding-right: 10px;
}
.leenk-mobile {
  color: black;
  text-decoration: none;
}
.phone-ul li:hover {
  color: white;
}

/* .leenk-mobile:hover {
  color: white;
} */
.sidebar-links ul li:hover {
  background: #222;
  color: white;
}
.sidebar-links ul li a:hover {
  background: #222;
  color: white;
}

.phone-sub-menu1 ul {
  display: none;
}
.phone-sub-menu1 ul li {
  border-bottom: 1px dotted #222;

}
.phone-sub-menu1 ul li:last-child {
  border-bottom: none;
}

.phone-sub-menu1 ul a {
  text-decoration: none;
  color: black;
}

.phone-subnav:hover .phone-sub-menu1 ul {
  display: block;
  position: absolute;
  background: #c69731;
  margin-top: -21px;
  margin-left: 70px;
  width: 130px;
} 


.sidebar-links ul li:hover .phone-sub-menu1 ul {
  display: block;
  /* margin: 10px; */
}
.phone-sub-menu1 ul {
  margin-top: 0;
}
.phone-book {
  background: #222;
  color: white;
  padding: 10px 28px;
  border: none;
  cursor: pointer;
}
.phone-book {
  width: 190px;
  /* margin: 10px; */
  padding: 10px;
  background: #c69731;
  border-bottom: 1px dotted #222;
  cursor: pointer;
  transition: all 0.3s;
  color: white;
}
.phone-book-arrow {
  padding-left: 5px;
}
.phone-book:hover {
  background: #161922;
  color: white
}




@media screen and (min-width: 980px) {
  .sidebar-wrapper {
    display: none;
  }
  .sidebar-links {
    margin-left: -20px;
  }
  
}
@media(max-width: 580px) {
  .ket-logo {
    width: 130px;
    height: 100px;
  }
  
}
@media(max-width: 410px) {
  .ket-logo {
    width: 100px;
    height: 70px;
  }
}


/* ##################### */




/* @@@@@@ Mobile @@@@@@ */

@media(max-width: 1050px) {
  .book, .leenk, .nav-list li {
    padding: 10px 20px;
    font-size: 13px;
    
  }
  .nav-centerDiv {
    justify-content: center;
  }
}
@media(max-width: 980px) {
  .main-nav {
    display: flex;
    justify-content: space-between;
    width: 80%;
    height: 100px;
    margin: auto;
  }
  .bookdiv {
    display: block;
  }
  .nav-list {
    display: none;
  }
  .nav-icon {
    visibility: visible;
  }

}
@media(max-width: 650px) {
  .angle {
    font-size: 20px;
    top: 60%;
  }
  .angle.welcome {
    top: 60%;
  }
  .nav-icon {
    font-size: 20px;
  }
}




/* @@@@@@@@@@END of NAVBAR@@@@@@@@ */


/* @@@@@@@@@@ RESERVE @@@@@@@@ */
.ket-topDiv {
  text-align: center;
  margin-top: 50px;
}
.ket-textBold {
  font-weight: bold;
}
.ket-topDiv {
  width: 80%;
  height: 100%;
  /* background: #c69731; */
  margin: auto;
  /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */

}
.ket-topDiv h2 {
  margin-top: 20px;
  font-family: "Playfair Display", serif;
  margin-bottom: 20px;
}
.book-unds {
  width: 100px;
  height: 2px;
  background: #c69731;
  margin: auto;
}
.buk-p {
  margin-top: 20px;
}
.reserve-sector {
  width: 90%;
  height: 100%;
  display: flex;
  justify-content: center;
  margin: auto;
  /* border-top: 1px solid #767a86; */
  padding-top: 20px;
  
}
.rooms-price {
  width: 30%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #161922;
  text-align: center;
  /* margin-top: 50px; */
  margin-left: 20px;
  padding-bottom: 10px;
  padding-top: 10px;
  margin-bottom: 30px;
  
}
.rooms-price-div {
  /* width: 100%; */
  margin: 10px;
  border: 1px solid #c69731;
  padding: 10px;
}
.rooms-price-one {
  width: 100%;
  height: 100%;
  /* margin-bottom: 5px; */
}
.rev-sp {
  color: #c69731;
}
.rooms-price-one {
  margin-bottom: 10px;
}
.vationInps {
  width: 40%;
  height: 100%;
  padding: 10px 20px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  margin-bottom: 20px;

}
.txt-Inp {
  padding: 10px 8px;
  width: 300px;
  margin-bottom: 10px;
  outline: none;
  border: 1px solid #c69731;
}
.txt-Inp-ket {
  width: 100px;
  margin-left: 10px;
  text-align: center;

}
.date-inp {
  margin-bottom: 20px;
  padding: 10px 8px;
  width: 300px;
  border: 1px solid #c69731;

}
.add-width {
  width: 300px;
}
.back-home {
  color: #c69731;
  font-weight: bold;
}
.back-home:hover {
  color: black;
}

@media(max-width: 930px) {
  .rooms-price {
    width: 40%;
    margin-left: 0px;
  }
  .vationInps {
    width: 50%;
  }
}

@media(max-width: 780px) {
  .ket-topDiv {
    width: 90%;
  }
  .reserve-sector {
    flex-direction: column;
  
  }

  .rooms-price {
    width: 80%;
    margin: auto;
    border: none;
  }
  .vationInps {
    width: 95%;
    margin: auto;

  }
}
@media(max-width: 500px) {
  .txt-Inp {
    width: 100%;
  }
  .date-inp {
    margin-bottom: 20px;
    padding: 10px 8px;
    width: 100%;
    border: 1px solid #c69731;
  }
  
  .add-width {
    width: 242px;
  }
  .dec-margin {
    margin-bottom: 5px;
  }

}


/* @@@@@@@@@@ End of RESERVE @@@@@@@@ */


.progress {
  /* background-color: #c69731; */
  position: fixed;
  bottom: 20px;
  right: 10px;
  height: 70px;
  width: 70px;
  display: none;
  place-items: center;
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  z-index: 3;
}
.progress-value {
  display: block;
  height: calc(100% - 15px);
  width: calc(100% - 15px);
  background-color: #fff;
  border-radius: 50%;
  display: grid;
  place-items: center;
  font-size: 25px;

}

/* @@@@@@@@@@  MAMA KET House @@@@@@@ */
.ket-description {
  width: 85%;
  height: 100%;
  display: flex;
  margin: auto;
  margin-top: 70px;
  margin-bottom: 40px;
}
.ket-text {
  width: 45%;
  margin-top: 100px;
}
.your-cozy {
  text-align: center;
}
.your-cozy h5 {
  font-size: 18px;
  color: #c69731;
}
.your-cozy h1 {
  font-family: "Playfair Display", serif;
  font-size: 75px;
}

.cozy-underscore {
  width: 80px;
  height: 2px;
  background: #c69731;
  margin-top: 20px;
  margin-bottom: 20px;
}
.your-cozy-text p {
  line-height: 1.8rem;
}
.your-cozy-text span {
  font-weight: bolder;
}

.ket-images {
  width: 55%;
  min-height: 100vh;
  height: 100%;
  display: flex;

}
.ket-images img {
  margin: 10px;
}
.ket-photo-long {
  width: 100%;
  height: 70%;
}
.ket-photo-short {
  width: 100%;
  height: 30%;
}
.space-top {
  padding-top: 50px;
}
.space-bottom {
  padding-bottom: 50px;
}
.ket-interior {
  margin-right: 23px;
}

/* @@@@@FEATURES@@@@@@ */
.house-features {
  width: 85%;
  height: 100%;
  margin: auto;
  /* background: yellowgreen; */
  margin-top: 80px;
  text-align: center;
  margin-bottom: 30px;

}
.ft-span {
  text-align: center;
  padding-top: 30px;
  border-bottom: 2px solid #c69731;
  font-size: 30px;
}
.house-Fa {
  padding-right: 10px;
}
.house-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: auto;

}
.house-text {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 25px;
  margin-top: 20px;
}

.sp-text {
  color: #c69731;
}
@media(max-width: 980px) {
  .ket-description {
    width: 94%;
  }
  .your-cozy h1 {
    font-size: 50px;
  }
  .your-cozy h5 {
    font-size: 15px;
  }

}
@media(max-width: 870px) {
  .house-features {
    margin-top: 40px;
  }
  .ft-span {
    padding-top: 10px;
  }
  .house-div {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 0.5em;
  }
  .house-text {
    justify-content: center;
    font-size: 20px;
  }
  .house-Fa {
    padding-right: 2px;
  }
}
@media(max-width: 800px) {
  .ket-description {
    flex-direction: column;
    margin-top: 5px;
    
    /* margin-bottom: 5px; */
  }
  .cozy-underscore,
  .ket-text,
  .ket-images {
    width: 100%;
  }
  .house-features {
    margin-top: 10px;
  }
}
@media(max-width: 660px) {
  .house-features {
    margin-top: 0px;
  }
}

@media(max-width: 490px) {
  .your-cozy-text {
    width: 94%;
    margin: auto;
  }
  .ket-text {
    margin-top: 40px;
  }
  .ket-images {
    width: 94%;
    height: 100%;
    margin: auto;
  }
  /* .ket-images {
    width: 55%;
    min-height: 100vh;
    height: 100%;
    display: flex;
  
  } */
  .ket-interior {
    display: none;
  }
  .ket-green {
    width: 100%;
    /* margin: auto; */
  }
  .ket-images img {
    margin: 0;
  }
  .ket-photo-long,
  .ket-photo-short {
    width: 100%;
    height: 300px;
    /* margin: 5px; */
  }
  .ket-photo-long {
    height: 400px;
  }
  .space-bottom {
    margin-bottom: 0;
  } 

  .progress {
    width: 50px;
    height: 50px;
  }
  .progress-value {
    font-size: 15px;
  }

   

}

@media(max-width: 440px) {
  .house-features {
    margin-top: -20px;
  } 
}


/* @@@@@@@@@@ END of  MAMA KET House @@@@@@@ */

/* @@@@@@@@@@ Our ROOMS @@@@@@@ */

.roomsMain {
  width: 94%;
  margin: auto;
  padding-top: 60px;
  height: 100%;
  /* margin-bottom: 120px; */
}
.comfort {
  text-align: center;
  margin-top: 18px;
  margin-bottom: 23px;
}
.comfort p {
  color: #c69731;
}
.comfort h2 {
  font-size: 40px;
  margin-top: 18px;
  margin-bottom: 18px;
}
.rm-underscore {
  height: 2px;
  width: 80px;
  background: #c69731;
  margin: auto;
}
.Our-rooms {
  width: 100%;
  display: flex;
  height: 100%;
  justify-content: space-between;
  margin: auto;
  /* margin-bottom: 30px; */

}
.individual-room {
  width: 30%;
  height: 600px;
  display: flex;
  flex-direction: column;
  /* margin: 8px; */
  text-align: center;
  /* margin: auto; */
}
.individual-room img {
  width: 100%;
  height: 400px;
  border-radius: 10px;
}
.individual-room span {
  color: #c69731;
  /* color: #61481C; */
}
.sq-btnDiv {
  text-align: center;
  margin-top: 70px;

}
.rm-price {
  margin-top: 10px;
}
.room-btn {
  padding: 13px 25px;
  color: white;
  background: black;
  border: none;
  font-size: 14px;
  font-weight: bolder;
  text-align: center;
  transition: 0.5s;
  cursor: pointer;
}
.room-btn:hover {
  scale: 1.15;
  background: #c69731;
}
.room-btn .fa-sqaure {
  font-size: 8px;
}
@media(max-width: 1050px) {
  .Our-rooms {
    /* margin-top: 50px; */
    height: 450px;
  }
}

@media(max-width: 950px) {
.individual-room img {
  height: 300px;
}
.sq-btnDiv {
  margin-top: -20px;
}

}
@media(max-width: 680px) {
  .Our-rooms {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column; 
  }
  
  .individual-room {
    width: 100%;
    height: 400px;
    margin-bottom: 20px;
  }
  
}
@media(max-width: 450px) {
  .roomsMain {
    margin-bottom: 50px;
  }
  .individual-room img {
    height: 100%;

  }
  .sq-btnDiv {
    margin-top: 20px;
  }
}

/* @@@@@@@@@@ End of Our ROOMS @@@@@@@ */

/* @@@@@@@@@@ Mesahouse @@@@@@@ */

.Mesahouse {
  width: 94%;
  height: 100%;
  display: flex;
  justify-content: space-between;

  margin: auto;
  margin-top: 50px;
}
.mesa-hs-text {
  width: 40%;
  margin-top: 40px;
}

.mesa-hs-text h1 {
  font-family: "Playfair Display", serif;
  font-size: 75px;
  text-align: center;
}
.mesa-hs-text h5 {
  font-size: 18px;
  color: #c69731;
  text-align: center;

}


.mesa-hs-text p {
  font-family: "Poppins", sans-serif;
  font-size: 15px;

}
.mesa-hs-text span {
  font-weight: bold;

}
.muka-imgsec {
  width: 50%;
  height: 700px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* background: #256b93; */
  /* margin: auto; */
 }
.muka-imgsec img {
 width: 450px;
 height: 400px;
}

.mesa-hs-images {
  height: 80vh;
  width: 60%;
  display: none;
}
.mesa-hs-images img {
  width: 80%;
  height: 80%;
}
.mesa-hs-text p {
  line-height: 1.8rem;
  padding-top: 20px;
  padding-bottom: 20px;

}
.mesa-hs-btn2 {
  visibility: hidden;
  height: 50px;
  width: 50%;
  margin: auto;
}


.mesa-hs-text button,
.mesa-hs-btn2 button {
  padding: 13px 25px;
  color: white;
  background: #c69731;
  border: none;
  font-size: 14px;
  font-weight: bolder;
  text-align: center;
  transition: 0.5s;
  border-radius: 5px;
}
.mesa-hs-btn1 {
  cursor: pointer;
}
.mesa-hs-btn1:hover {
  background: #c69731;
}
.mesa-hs-text button,
.mesa-hs-btn2 button:hover {
  scale: 1.15;
  background: black;
}

.mesa-hs-text button .fa-sqaure {
  font-size: 8px;
}
.mesa-hs-btn2 button .fa-sqaure {
  font-size: 8px;
}

@media(max-width: 790px) {
  .Mesahouse {
    flex-direction: column;
    
  }
  .mesa-hs-text h1 {
    font-size: 50px;
  }
  .mesa-hs-text h5 {
    font-size: 15px;
    text-align: center;
  }
  .muka-imgsec {
    width: 90%;
    margin: auto;
  }
  .mesa-hs-images {
    height: 100%;
    width: 100%;
    
  }
  .mesa-hs-images img {
    height: 450px;
  }
  .mesa-hs-text {
    width: 100%;
    /* margin-top: 40px; */
  }
  .mesa-hs-btn1 {
    display: none;
  }
  .mesa-hs-images img {
    width: 100%;
    height: 100%;
  }
  .mesa-hs-btn2 {
    /* display: block; */
    visibility: visible;
  }
}
@media(max-width: 450px) {
  .mesa-hs-btn2 {
    margin-top: 20px;
  }
}



/* @@@@@@@@@@ End of Mesahouse @@@@@@@ */



/* @@@@@@@@@@ AMENITIES @@@@@@@ */
.amenities {
  display: flex;
  height: 700px;
  margin-top: 50px;
  color: white;
  margin-top: 150px;
}

.amenities img {
  width: 50%;
  height: 100%;
}
.amenities-text {
  width: 50%;
  height: 100%;
  background: #1E201E;
  padding: 50px;

}
.amenities-text h1 {
  font-size: 40px;
  margin-bottom: 25px;
}
.amenities-text p {
  margin-bottom: 25px;
  line-height: 1.5em;

}

.am-points {
  display: flex;
  justify-content: left;
  align-items: center;
  height: 25px;
}
.fa-am {
  padding-right: 10px;
}
@media(max-width: 930px) {
  .amenities {
    margin-top: 100px;
  }
}

@media(max-width: 810px) {
  .amenities img {
    display: none;
  }
  .amenities-text {
    width: 100%;
  }
}
@media(max-width: 530px) {
  .amenities {
    margin-top: 70px;
  }
}

/* @@@@@@@@@@ End of AMENITIES @@@@@@@ */



/* @@@@@@@@@@ GALLERY @@@@@@@ */
.Gat-computer {
  width: 94%;
  height: 100%;
  margin: auto;
  margin-top: 50px;
  text-align: center;
  box-sizing: border-box;
}

.Gat-computer p {
  color: #c69731;
}
.Gat-computer h2 {
  font-size: 35px;
  margin: 20px 0;
}
.g-underscore {
  width: 70px;
  height: 2px;
  background: #c69731;
  text-align: center;
  margin: auto;
  margin-bottom: 50px;
}
.ket-mesa-buttons {
  margin-bottom: 20px;
}

.KET-btn,
.MESA-btn {
  font-size: 25px;
  padding: 8px 25px;
  cursor: pointer;
  border: none;
}
.btn-color {
  background: #c69731;
  color: white;
}
.gal-active { 
  display: none;
}
.gket-images,
.gmesa-images {
  min-height: calc(100vh - 100px);
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-template-rows: repeat(auto-fit, minmax(96px, 1fr));
  gap: 8px;
}
.g-mobile-show {
  display: none;
}
.gal-photos {
  width: 100%;
  height: 100%;
}
@media(max-width: 450px) {
  .KET-btn,
  .MESA-btn {
    font-size: 15px;
    padding: 8px 20px;
    cursor: pointer;
  }
  .g-computer-show {
    display: none;
  }
  .g-mobile-show {
    display: block;
    height: 300px;
    width: 100%;
  }
  .gket-images,
  .gmesa-images {
    display: inline-block;
    /* justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%; */
  }
  .gal-photos {
    width: 100%;
    height: 300px;
  }
}


/* @@@@@@@@@@ End of GALLERY @@@@@@@ */


/* @@@@@@@@@@ BEST EXPERIENCE @@@@@@@ */

.best-experience {
  width: 100%;
	height: 100vh;
	display: flex;
  flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: var(--body-clr);
  color: white;
  margin-top: 50px;
}
.best-experience h2 {
  color: #c69731;
  font-size: 30px;
  padding-top: 30px;
}

.clients-remarks {
  width: 90%;
  margin: auto;
  display: flex;
  overflow: hidden;
}
.clients-sliding-div {
  width: 50%;
  height: 100%;
  /* background: #767a86; */
  background: #0f0f0f;
  padding: 10px;
  margin: 0 5px;
}
.be-quote {
  font-size: 30px;
  padding-right: 10px;
  color: #c69731;

}
.clients-star {
  color: #c69731;
}
.cs-div1 {
  text-align: center;
}
.cs-div1 p {
  font-size: 13px;
  line-height: 1.1rem;
  letter-spacing: 0.06rem;
}
.cs-div1 span {
  font-size: 13px;
  color: #c69731;

}

.clients-img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
}
.clients-name {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
.quote-div {
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 15px 0;
  /* background-color: #256b93; */
}
.quote-div h4 {
  margin-top: 6px;
  margin-left: 3px;
}
.clients-name h4 {
  color: #c69731;
}
.be-arrows {
  width: 80%;
  /* height: 100%; */
  display: flex;
  justify-content: right;
  margin: auto;
  /* background-color: #767a86; */
  
}
.fa-arrow {
  color: #c69731;
  margin: 20px;
  font-size: 25px;
  cursor: pointer;
}
.fa-arrow:hover {
  color: white;
}

/* ########Mobile##### */

@media(max-width: 780px) {
  
  .fa-arrow {
    margin: -5px 15px;
    font-size: 20px;
  }
  .cs-div1 p {
    font-size: 10px;
    line-height: 0.9rem;
    letter-spacing: 0.05rem;
  }
  .clients-img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
  }
  .be-arrows {
    margin-top: -20px;
  }

}
@media(max-width: 640px) {
  .best-experience h2 {
    font-size: 15px;
  }
  .clients-sliding-div {
    width: 50%;

    height: 650px;
    
    /* background: #767a86; */
    background: #0f0f0f;
    padding: 10px;
    margin: 0 5px;
  }
  .quote-div  {
    flex-direction: column;
    height: 100%;
  }
  .quote-div h4 {
    font-size: 10px;
    margin-top: 0px;
    margin-left: 0px;
  }
  
  .quote-div span {
    font-size: 13px;
  }
  .be-quote {
    font-size: 35px;
  }
  .clients-name h4, span {
    font-size: 12px;
    
  }
  .be-arrows {
    margin-top: -10px;
  }
  .fa-arrow {
    /* margin: -5px 15px; */
    font-size: 15px;
  }
}
@media(max-width: 490px) {
  .best-experience {
    height: 100%;
    /* padding-bottom: 20px; */
  }
  .be-arrows {
    margin-top: 20px;
    height: 60px;
  }
}

/* @@@@@@@@@@ End of BEST EXPERIENC @@@@@@@ */



/* @@@@@@@@@@ CONTACT PAGE @@@@@@@ */


.socials-main {
  width: 100%;
  height: 100%;
  background-color: #256b93;
  margin-top: 4rem;
  padding-top: 20px;
  margin-bottom: 50px;
}

.socials-form {
  width: 90%;
  height: 100%;
  display: flex;
  margin: auto;
  padding-top: 30px;
  padding-bottom: 30px;
  /* margin: auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(310px, 1fr));
  gap: 1rem;
  margin-top: 4rem;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-bottom: 60px; */

}
.hm-contact {
  width: 90%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}
.input-div {
  display: flex;
  flex-direction: column;
  /* background-color: #13547a; */
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  padding: 10px;
  box-sizing: border-box;
}
.input-div input,
.input-div textarea, select {
  width: 100%;
  padding: 15px;
  margin-bottom: 17px;
  outline: none;
  border: 1px solid #c69731;
  border-radius: 4px;
}
.contact-text {
  width: 50%;
  /* min-height: 800px; */
  /* background: greenyellow; */

}
.contact-mmForm {
  width: 50%;
  margin-top: 100px;
}

.contact-text h1 {
  font-size: 40px;
}
.c-underscore {
  width: 100px;
  height: 2px;
  background: #c69731;
  margin: 30px 0;
}
.for-distance {
  padding-bottom: 20px;
}
.sub-contact {
  margin-top: 20px;
  /* min-height: calc(100px - 50px); */
  min-height: 300px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-template-rows: repeat(auto-fit, minmax(96px, 1fr));
  gap: 8px;
  
}
.sub-sub-contact {
  display: flex;
  /* justify-content: space-between; */
  height: 100%;
  width: 100%;
  align-items: center;
}
.sub-contact-fa {
  color: #c69731;
  font-size: 25px;
  margin-right: 10px;
}
.feeback {
  visibility: hidden;
}
.refresh-btnB {
  visibility: hidden;
}
.refresh-btnC {
  visibility: hidden;
}
.formBtn {
  box-sizing: border-box;
  width: 100%;
  padding: 15px 0;
  border: none;
  background: #c69731;
  color: white;
  font-weight: bolder;
  border-radius: 4px;
  cursor: pointer;
  transition: 1s;
}
.formBtn:hover {
  border: none;
  background-color: black;
  color: #fff;
}

@media(max-width: 850px) {

  .hm-contact {
    flex-direction: column;
    width: 95%;
    
  }
  .contact-text {
    width: 90%;
    /* margin: auto; */
    margin-top: 20px;
  }
  .contact-mmForm {
    width: 100%;
    margin-top: 20px;

  }
  .formBtn {
    box-sizing: border-box;
    width: 100%;
    /* padding: 5px 0; */
    
  }
}
.refresh-btn {
  visibility: hidden;
}
@media(max-width: 450px) {
  .sub-contact {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    margin: auto;
  }
  
  .sub-sub-contact {
    justify-content: left;

    width: 80%;
    height: 100%;
    margin: auto;
    padding-bottom: 20px;
  }
}

/* @@@@@@@@@@ End of CONTACT PAGE @@@@@@@ */





/* @@@@@@@@@@ Start of LINK PAGES @@@@@@@ */

/* @@@@@@@@@@ Structure for all room PAGES @@@@@@@ */

/* @@@@@@@@@@ ALLHouse @@@@@@@ */

.allhse-section {
  width: 94%;
  min-height: calc(100vh - 100px);
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-template-rows: repeat(auto-fit, minmax(96px, 1fr));
  gap: 8px;
  margin: auto;
  margin-top: 30px;
}
.our-houses {
  width: 300px;
  height: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}
.hseee {
  width: 100px;
  height: 2px;
  background: #c69731;
  text-align: center;
}
.hseeeH1 {
  text-align: center;
  /* border-bottom: 2px solid #c69731; */
  /* width: 100px; */
}
.the-allhse {
  text-align: center;
}
.the-allhse-img {
  width: 350px;
  height: 300px;
  border-radius: 5px;
}
.the-allhse p {
  margin-bottom: 20px;

}
.hgt-inc {
  width: 100%;
  /* height: 100%; */
  margin-bottom: 20px;
}
.span-yw {
  color: #c69731;
}

.view-li {
  text-decoration: none;
  padding: 8px 15px;
  background: #c69731;
  color: white;
  transition: .5s;
}
.view-li:hover {
  background: black;
}
.change-houses-link {
  text-decoration: none;
  text-align: center;
}
.change-houses-link button {
  padding: 12px 23px;
  background: black;
  color: white;
  border: none;
  margin-bottom: 20px;
  cursor: pointer;
  transition: .5s;

}
.tyyyy {
  padding-right: 10px;
  font-size: 20px;
}

.change-houses {
  height: 100%;
  width: 100%;
  margin: auto;
  text-align: center;
  margin-bottom: 30px;
  overflow: hidden;

}
.change-houses p {
  /* padding-right: 10px; */
  padding: 15px ;
}
.change-houses-link button:hover {
  background: #c69731;
  color: white;
}
@media(max-width: 770px) {
  .change-houses {
    margin-top: 30px;
  }
}

@media(max-width: 500px) {
  .the-allhse {
    margin-bottom: 20px;
    
  }
  .tyyyy {
    padding-right: 10px;
    font-size: 13px;
  }
}

/* @@@@@@@@@@ End of ALLHouse @@@@@@@ */


/* @@@@@@@@@@ Mesa House @@@@@@@ */

.mn-sub-bar {
  width: 100%;
  height: 100%;
  margin-top: 20px;
}

.sub-bar {
  width: 60%;
  height: 200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
  /* background: rgb(24, 234, 24); */
  /* background: #191d28; */
  /* background: linear-gradient(rgba(0,0,0,0.2),rgba(0,0,0,0.7)); */
  background: linear-gradient(rgba(25,29,40,0.2),rgba(198,151,49,0.7));

  color: white;
  margin: auto;

}

.return-btn{
  padding: 8px 15px;
  /* background: #c69731; */
  /* border: none; */
  color: white;
  /* text-decoration: none; */
  cursor: pointer;
  transition: .5s;
}
.return-btn:hover {
  /* background: black; */
  color: black;

}
.hse-background {
  height: 100vh;
  /* background-color: #767a86; */
  background-image: url("./Background-images/mesa.jpg");
  background-position: center;
  background-size: cover;
}
.hse-background-ket {
  height: 100vh;
  /* background-color: #767a86; */
  background-image: url("./Background-images/ket.jpg");
  background-position: center;
  background-size: cover;
}
.hse-background-stdRoom {
  height: 100vh;
  /* background-color: #767a86; */
  background-image: url("./Background-images/stdroom.jpg");
  background-position: center;
  background-size: cover;
}
.hse-background-double {
  height: 100vh;
  /* background-color: #767a86; */
  background-image: url("./Background-images/dbroom.jpg");
  background-position: center;
  background-size: cover;
} 
.hse-background-tripple {
  height: 100vh;
  /* background-color: #767a86; */
  background-image: url("./Background-images/tproom.jpg");
  background-position: center;
  background-size: cover;
}
.hse-mesaH {
  width: 80%;
  height: 100%;
  padding-top: 50px;
  text-align: center;
  color: white;
  margin: auto;
}
.hse-mesaH h1 {
  font-size: 50px;
}
.hseP {
  color: white;
}
.bold-txt {
  font-weight: bolder;
  /* color: #c69731; */

}
.bold-ttrr {
  color: #161922;
  font-weight: bold;
}
.hse-ffp {
  color: #c69731;
  font-family: "Playfair Display", serif;

}
.hse-Aside-div {
  /* position: relative; */
  display: flex;
  width: 90%;
  height: 100%;
  justify-content: left;
  align-items: center;
  margin: auto;
  margin-top: 50px;
}
.hse-imgside {
  width: 70%;
}
.hse-msPoints {
  margin-top: 20px;
}
.hse-msPoints-div {
  display: flex;
  font-size: 15px;
  height: 30px;
  width: 100%;
  justify-content: left;
  align-items: center;
}
.hse-msPoints-div span {
  font-size: 5px;
  padding-right: 5px;
}
.Fa-para {
  color: black;
  font-size: 5px;
  padding-right: 5px;
}

.hse-img1 {
  width: 100%;
  height: 500px;
  margin-bottom: 20px;
}
.hse-otherImg {
  min-height: calc(100vh - 100px);
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-template-rows: repeat(auto-fit, minmax(96px, 1fr));
  gap: 8px;
}
.hse-otherImg img {
  width: 258px;
  height: 200px;
}

.booside-sec {
  width: 30%;
  height: 100%;
}
.hse-bookide {
  
  width: 100%;
  /* max-width: 330px; */
  height: 100;
  /* background-color: #256b93; */
  border: 1px solid rgb(39, 40, 37);
  margin-left: 30px;
  padding: 15px;
  
}
.hse-bookide-div1 {
  border: 1px dotted black;
  padding: 10px;
  padding-bottom: 20px;
}
.hse-bookide-div1 aside {
  display: flex;
  margin-bottom: 20px;

}
.bk-underscore {
  width: 2px;
  height: 70px;
  background: #c69731;
  margin-right: 10px;
}
.hse-bookide-div1 aside h2 {
  font-size: 30px;
}
.cm-bdroom {
  font-weight: bold;
}
.rum-amn {
  width: 100%;
  height: 20px;
  display: flex;
  justify-content: left;
  align-items: center;
  /* background: #256b93; */
  /* margin-bottom: 5px */
}
.book-btn,
.cu-btn {
  padding: 12px 25px;
  background: #c69731;
  border: none;
  color: white;
  font-weight: bold;
  margin-top: 15px;
  cursor: pointer;
  transition: 0.5s;
}
.book-btn {
  text-decoration: none;
}

.book-btn:hover {
  background: black;
}
.cu-btn:hover {
  background: black;
}
.hse-bookide-div2 {
  margin-top: 20px;
}
.hse-bookide-div2 h2 {
  font-size: 30px;
}

/* mobile */
@media(max-width: 850px) {
  .sub-bar {
    width: 90%;
  }
  .hse-Aside-div {
    flex-direction: column;
  }
  .hse-imgside {
    width: 90%;
    margin-bottom: 20px;
  }
  .booside-sec {
    width: 90%;
    margin-bottom: 30px;
  }
  .hse-bookide {
    margin-left: 0;
  }

}

@media(max-width: 450px) {
  .sub-bar {
    height: 100px;
  }
  .sub-bar-logo img {
    width: 50px;
    height: 50px;
  }
  .hse-img1 {
    width: 100%;
    height: 300px;
  }
  .hse-otherImg img {
    width: 100%;
    height: 300px;
  }
  
  .contact-mmForm {
    /* width: 90%; */
    margin-left: 0px;

  }

}





/* @@@@@@@@@@ end of Room page@@@@@@@ */


/* @@@@@@@@@@ End of LINK PAGES @@@@@@@ */



/* @@@@@@@@@@ BOOKING PAGE @@@@@@@ */

.OrderForm {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
  visibility: hidden;
  z-index: -1;
  transition: var(--transition);
  transform: scale(0);
  background: rgba(0, 0, 0, 0.5);
}
.showForm {
  visibility: visible;
  z-index: 2;
  transform: scale(1);
}
.sidebar-Aside {
  width: 90vw;
  height: 95vh;
  max-width: var(--fixed-width);
  background: var(--clr-white);
  border-radius: var(--radius);
  box-shadow: var(--dark-shadow);
  position: relative;
  padding: 4rem 2rem;
}
.res-tittle {
  margin-bottom: 20px;
}
.sidebar-Aside-div input {
  padding: 8px 15px;
  margin-bottom: 10px;
  outline: none;
  border: 1px solid #c69731;
  /* font-size: 15px; */
}
.submitBooking {
  width: 210px;
  padding: 8px 15px;
  background: #c69731;
  color: white;
  border: none;
  font-size: 20px;
  margin: 20px 0;
  transition: .5s;
  cursor: pointer;
}
.submitBooking:hover {
  background: black;
  color: white;
}

.inp-width {
  width: 220px;
}
.seperate-inp {
  display: none;
}
.inp-space {
  margin-right: 10px;
}
.close-btn {
  font-size: 2rem;
  background: transparent;
  border-color: transparent;
  color: #c69731;
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
}
.close-btn:hover {
  color: #222;
}
.sidebar article {
  margin-bottom: 2rem;
}

@media(max-width: 530px) {
  .seperate-inp {
    display: block;
  }
}


/* @@@@@@@@@@ End of BOOKING PAGE @@@@@@@ */

/* ########FOOTER##### */

.footer-div1 {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #191d28;
  color: #c69731;
  cursor: pointer;

}
.footer-div1 span {
  padding: 0 20px;
}
.footer-div2 {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #0f0f0f;
  color: white;

}
.footer-div2 a {
  /* text-decoration: none; */
  color: white;
}

.copy-C, 
.nick {
  padding: 0 10px;
}

.footer-div2 {
  font-size: 10px;
}
.footer-div2 span {
  font-size: 15px;
}
.C-year {
  padding: 0 7px;
}


/* ######## END OF FOOTER##### */

/* ######## start OF About ##### */
.banner {
  width: 100%;
  height: 100vh;
  position: relative;

}
.BOG {
  width: 100%;
  height: 100vh;
}
.b-slider {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
}

.overlay {
  width: 100%;
  height: 100vh;
  background: linear-gradient(rgba(0,0,0,0.7),rgba(0,0,0,0.7));
  position: absolute;
  top: 0;
  color: white;
  padding: 0 60px;
  text-align: center;
}

.return-om {
  width: 90%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
}
.about-logo {
  width: 150px;
  height: 100px;
}
.om-link {
  color: white;
  cursor: pointer;
  padding-right: 15px;
}
.om-link:hover {
  color: #c69731;
}

.about-text {
  width: 90%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* margin-left: 18px; */
  text-align: center;
  margin: auto;
}
.abt-extra {
  width: 100%;
  height: 100%;
  margin-top: 50px;
  text-align: center;


  /* margin: auto; */
}
.abs-unds {
  width: 100px;
  height: 2px;
  background: #c69731;
  margin-bottom: 20px;
  text-align: center;
  margin: auto;
}

.about-pge {
  margin-top: 30px;
}
/* Contact-page */
.socials-form-contact {
  width: 94%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  /* margin: auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(310px, 1fr));
  gap: 1rem;
  /* margin-top: 4rem; */
  /* padding-top: 20px;
  padding-bottom: 20px;
  margin-bottom: 60px; */

}

/* ************ EDITTED ************** */
.enqContainer {
  min-height: 100vh;
  width: 100%;
  background: url("./Background-images/snbg.png");
  background-position: center;
  background-size: cover;
  
}
.endForm {
  height: 100%;
  width: 100%;
  display: flex;
  padding: 20px;
  /* background: yellowgreen; */
  margin-top: 30px;
}
.endForm-text {
  width: 50%;
  margin-right: 15px;
}
.endForm-inp {
  width: 50%;
  margin-top: 60px;
}

@media(max-width: 760px) {
  .endForm {
    flex-direction: column;
  }

  .endForm-text {
    width: 90%;
    margin-right: 0px;
    height: 100%;
    margin: auto;
  }
  .endForm-inp {
    width: 90%;
    height: 100%;
    margin: auto;
  }

}

/* ************ END of EDITTED ************** */

.overlay-contact {
  width: 100%;
  /* height: 120vh; */
  height: 100%;
  background: linear-gradient(rgba(0,0,0,0.7),rgba(0,0,0,0.7));
  /* position: absolute;
  top: 0; */
  color: white;
  padding: 0 60px;
  text-align: center;
}
.con-h1 {
  border-bottom: 2px solid #c69731;
  margin-bottom: 30px;
  /* font-size: 30px; */
  color: white;
}
.input-div {
  text-align: left;
}
/* Contact-page */
@media(max-width: 980px) {
  .banner-contact,
  .overlay-contact {
    height: 180vh;
    padding: 0 20px;
  }
  .b-slider-contact {
    height: 180vh;

  }
  .BOG-contact {
    height: 180vh;

  }
}
@media(max-width: 900px) {
  .socials-form-contact {
    flex-direction: column;
    height: 100%;
  }
  .overlay-contact {
    height: 220vh;
  }
  .BOG-contact {
    height: 220vh;

  }
  .b-slider-contact {
    height: 220vh;

  }
  .contact-text {
    width: 100%;
  }

  
}


@media(max-width: 710px) {
  .banner-contact,
  .overlay-contact {
    height: 200vh;
  }
  .b-slider-contact {
    height: 200vh;

  }
  .BOG-contact {
    height: 200vh;

  }
}


@media(max-width: 660px) {
.overlay {
  /* text-align: left; */
  padding: 0 30px;
}
  .overlay h1 {
    margin-top: 20px;
  }

  .about-logo {
    width: 100px;
    height: 70px;
  }
  .con-h1 {
    font-size: 30px;
  
  }
  .sub-contact {
    height: 300px;
    display: flex;
    flex-direction: column;
  }
  .sub-sub-contact-conPage {
    display: flex;
    justify-content: left;

    width: 70%;
    /* height: 100%; */
    padding-bottom: 0px;
    height: 100px;
    text-align: left;
    /* background: #256b93; */
    margin: auto;


  }
  

}

@media(max-width: 410px) {
  .banner-contact,
  .overlay-contact {
    height: 140vh;
  }
  .b-slider-contact {
    height: 140vh;

  }
  .BOG-contact {
    height: 140vh;

  }
  .return-om {
    width: 96%;
  }
  .about-logo {
    width: 80px;
    height: 50px;
    margin-top: 10px;
  }
  .about-text {
    margin-top: 50px;
  }
}



/* ######## END OF About ##### */

/* @@@Standard colors@@@ */
.faded-white {
  color: var(--clr-grey-10);

}

.faded-blue {
  color: var(--clr-grey-5);
}
/* @@@ end of Colors @@@ */

.dec-margin {
  margin-bottom: 10px;
}
.dec-margin button {
  font-size: 10px;

}
.dec-margin span {
  font-size: 15px;

}
.ppwwp-nav {
  margin-bottom: 30px;
}
.ppwwp {
  padding: 0;
  margin: 0;
}
.intro-span {
  line-height: .5;
}
.ppwwp-fa {
  font-size: 13px;
  margin-right: 10px;
}
.mmmo-white {color: white;}

.about-text p {
  color: white;
}
.about-text span {
  font-weight: bold;
}
* {
  max-width: 100%;
}

@media(max-width: 660px) {
  .welcome-more {
    line-height: 1rem;
    display: grid;
  }
}


@media(max-width: 500px) {

  .dec-margin {
    margin: 5px;
  }
  /* .welcome-more {
    display: none;
  } */

  .welcome-more {
    line-height: 1rem;
    display: grid;
  }
}